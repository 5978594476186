.avatar-uploader img {
  max-height: 400px;
  width: 100%;
}

.avatar-uploader {
  width: 100%;
  max-width: 800px;
  height: auto;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
}