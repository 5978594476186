$primaryColor: #1155B0;
$defaultColor: #9C9C9C;
$warningColor: #FFC007;
$dangerColor: #ff4d4f;
$infoColor: #00A8F4;
$successColor: #239B23;

// Background Color

.primary-bg {
  background-color: $primaryColor !important;
}

.default-bg {
  background-color: $defaultColor !important;
}

.warning-bg {
  background-color: $warningColor !important;
}

.danger-bg {
  background-color: $dangerColor !important;
}

.info-bg {
  background-color: $infoColor !important;
}

.success-bg {
  background-color: $successColor !important;
}

// Font Color

.primary-font-color {
  color: $primaryColor !important;
}

.default-font-color {
  color: $defaultColor !important;
}

.warning-font-color {
  color: $warningColor !important;
}

.danger-font-color {
  color: $dangerColor !important;
}

.info-font-color {
  color: $infoColor !important;
}

.success-font-color {
  color: $successColor !important;
}

.button-icon,
button {
  min-width: 10px !important;
}

.page-header h2 {
  color: $primaryColor;

  .page-header-icon {
    color: $dangerColor;
  }
}

.ant-modal-close-x {
  margin-right: 0;
  margin-left: auto;
}

.sub-layout_sider {
  border-right: 1px solid #e8e8e8;

  .ant-menu-item-group-title {
    padding: 8px 16px 0px 16px !important;
  }

  .ant-menu-item {
    margin: 0px !important;
    height: auto !important;
    line-height: 30px !important;
    padding: 0px 0px 0px 16px !important;
  }

  .ant-menu-item-group-list .ant-menu-item {
    padding: 0 16px 0 35px !important;
  }

  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-right: 0 !important;
  }
}

// Pagination
.ant-pagination-item {
  &.ant-pagination-item-active {
    border-color: $primaryColor !important;
    background-color: $primaryColor !important;

    &:hover {
      border-color: #FFFFFF !important;
    }

    a {
      font-weight: bold !important;
      color: #FFFFFF !important;
      font-size: 1.1rem !important;
    }
  }
}

.ant-table-row,
.ant-table-tbody>tr>td {
  // border-bottom: 1px solid #888;
  border-top: 1px solid #f0f0f0;
}

.ant-table-row.row-even {
  background-color: transparent;
}

.ant-table-row.row-odd {
  background-color: transparent;
}

.ant-btn-primary {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
}

.ant-form-item {
  margin-bottom: 10px !important;

  .ant-form-item-label {
    line-height: 15px !important;
  }
}

.ant-card.search-card {
  width: calc(100% - 28.5%);
  border: transparent;
  box-shadow: 0 4px 15px #00000026;
  padding: 0px 30px;
  // margin-left: auto;
  // margin-right: auto;

  @media only screen and (max-width: 768px) {
    width: calc(100%);
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .ant-form-item-label {
    >label {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }

  .tip {
    color: #b0b0b0;
    font-size: 0.9rem;
    margin: 0;
  }

  input {
    height: 45px;
    // &:hover,
    // &:focus {
    //   border-color: #54c8bf;
    //   box-shadow: 1px solid #54c8bf !important;
    //   outline: none !important;
    // }
  }

  button {
    padding: 0px 50px;
    font-weight: 500;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }
}

.layout {
  .sub-layout {
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 80vh;

    .sub-layout_sider {
      // background-color: #FFFFFF;
      // border-right: 1px #CCCCCC solid;
    }

    .sub-layout-content {
      min-width: 1200px;
      max-width: 100%;
      height: 100%;
      // padding: 0px 50px;

      .page-content-control-button-holder {
        margin: 10px 0 20px 0;
      }
    }

    .page-title {
      color: #151b35;
    }

    .page-description {
      color: #151b35;
    }
  }
}

.search-result-item-container {
  background: #f8f8f8;
  padding-bottom: 50px;

  .results {
    >div:nth-child(even) {
      display: flex;
      justify-content: flex-end;
    }
  }

  .show-more {
    margin-top: 50px;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}