@import "./variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
html,
body {
  font-family: "Roboto" !important;
}

.ant-layout {
  background: none;
}

.ant-input,
.ant-select > div,
.ant-select-dropdown {
  border-radius: 0;
}

button {
  font-family: "Roboto";
  // font-size: $fs14;
  font-weight: 500;
  min-width: 120px;

  &.btn-primary {
    background-color: $primary;
    border-radius: 2px;
    border-color: $primary;
    color: #fff;
    &:hover,
    &:active,
    &:focus {
      background-color: $primary;
      border-color: $primary;
      color: #fff;
    }
    &:disabled,
    &:disabled:hover {
      background-color: $primary;
      border-color: $primary;
      color: #fff;
      opacity: 0.5;
    }
  }
}
